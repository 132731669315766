import React, { memo } from "react";

interface IProps {
    heigth?: number;
    width?: number;
    className?: string;
    isActive?: boolean;
    onClick?: (e) => void;
}

const LearnIcon: React.FC<IProps> = ({ width = 40, heigth = 40, className, onClick, isActive = false }) => {
    return (
        <svg
            width={width}
            height={heigth}
            className={className}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="learn_icon"
            onClick={onClick}
        >
            <g clip-path="url(#clip0_5773_2a6083)">
                <path
                    d="M32.1336 15.5998C30.0002 11.7331 25.7336 10.9331 22.0002 13.4665C21.8669 13.5998 21.6002 13.7331 21.3336 13.7331C21.3336 12.5331 21.7336 10.9331 23.0669 10.5331C23.7336 10.3998 24.2669 9.59982 24.0002 8.93315C23.8669 8.26648 23.0669 7.73315 22.4002 7.99982C19.6002 8.79982 18.6669 11.4665 18.5336 13.7331C18.4002 13.5998 18.2669 13.5998 18.1336 13.4665C14.4002 10.9331 10.1336 11.7331 8.00024 15.5998C6.53358 18.2665 6.40024 21.9998 7.46691 25.5998C9.73358 32.9331 15.6002 35.7331 18.9336 34.3998C19.8669 34.1331 20.4002 34.2665 21.2002 34.3998C25.7336 35.5998 30.6669 31.7331 32.6669 25.4665C33.7336 21.9998 33.4669 18.1331 32.1336 15.5998ZM30.0002 24.7998C28.6669 29.1998 25.0669 32.7998 21.7336 31.8665C20.4002 31.4665 18.9336 31.5998 18.0002 31.8665C15.0669 32.6665 11.3336 29.4665 9.86691 24.7998C8.93358 21.8665 9.06691 18.9331 10.1336 16.9331C11.4669 14.5331 13.8669 13.9998 16.5336 15.7331C18.6669 17.1998 21.3336 17.1998 23.3336 15.7331C25.7336 14.1331 28.2669 14.3998 29.6002 16.9331C30.8002 18.7998 30.9336 21.8665 30.0002 24.7998Z"
                    fill={isActive ? "#FD4D00" : "#B0B0B0"}
                />
                <path
                    d="M14.5338 17.6C14.0005 17.2 13.0671 17.3333 12.6671 18C12.0005 18.9333 11.6005 21.3333 12.5338 24C12.6671 24.5333 13.2005 24.9333 13.8671 24.9333C14.8005 24.9333 15.4671 24 15.2005 23.2C14.4005 21.0667 14.8005 19.6 14.9338 19.4667C15.3338 18.8 15.2005 18 14.5338 17.6Z"
                    fill={isActive ? "#FD4D00" : "#B0B0B0"}
                />
            </g>
            <defs>
                <clipPath id="clip0_5773_26083">
                    <rect width="26.6667" height="26.6667" fill="white" transform="translate(6.6665 8)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default memo(LearnIcon);
